<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24" v-if="false">
            <a-form-item label="用户名">
              <a-input v-model="queryParam.username" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="手机号">
              <a-input v-model="queryParam.phone" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="tags" slot-scope="tags">
        <a-tag
          v-for="tag in tags"
          :key="tag"
          :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
        >
          {{ tag }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="showResetPassWord(record)">重置密码</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  title="确定要删除么？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="handleDelet(record)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>

        </template>
      </span>
    </s-table>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <ResetForm
      v-if="resetvisible"
      ref="createResetModal"
      :visible="resetvisible"
      :confirm-loading="resetLoading"
      :model="resetmdl"
      @ok="resetPassWord"
      @cancel="handleResetCancel"
    >

    </ResetForm>
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '../modules/CreateForm'
import ResetForm from '../modules/ResetForm'
import { user_create, user_delete, user_list, user_reset_password_update } from '@/api/user'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    ResetForm
  },
  data () {
    return {
      configVisible: false,
      configLoading: false,
      resetvisible: false,
      resetLoading: false,
      resetmdl: {},
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户名',
          dataIndex: 'username'
        },
        {
          title: '手机号',
          dataIndex: 'phone'
        },
        {
          title: '操作',
          width: '250',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return user_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  methods: {
    showResetPassWord (record) {
      this.resetmdl = record
      this.resetvisible = true
      this.resetLoading = false
    },
    handleResetCancel () {
      this.resetvisible = false
      const form = this.$refs.createResetModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    resetPassWord () {
      const form = this.$refs.createResetModal.form
      this.resetLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
           const param = Object.assign({}, values)
          user_reset_password_update(param, values.id).then(res => {
            this.resetvisible = false
            this.resetLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('删除成功------')
          })
        } else {
          this.resetLoading = false
        }
      })
    },
    //  弹框处理区域
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      user_delete(record.id).then((res) => {
        console.log(res)
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          user_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
